import React from "react"
import PropTypes from "prop-types"
import { join } from "hooks/use-array"
import * as styles from "./toggle.module.less"

export default function Toggle({ state, onClick, children }) {
  return (
    <div className={styles.root}>
      <button
        className={join(styles.dial, state && styles.active)}
        onClick={() => {
          onClick(i => !i)
        }}
      >
        <span className={styles.btn} />
      </button>
      <div className={styles.text}>{children}</div>
    </div>
  )
}

Toggle.propTypes = {
  children: PropTypes.node,
}

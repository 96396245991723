import React from "react"
import Clamp from "components/clamp"
import Companies from "components/companies"
import Hero from "components/hero"
import Text from "components/text"
import Toggle from "components/toggle"

export default () => {
  const [state, setState] = React.useState(false)

  return (
    <>
      <Hero>
        GPI Capital provides customized capital solutions to help finance the
        needs of our partner companies
      </Hero>
      <Clamp inset>
        <h1>Our investments</h1>
        <Toggle state={state} onClick={setState}>
          {state
            ? "Click to see current investments only"
            : "Click to see all investments"}
        </Toggle>
        <h2>Technology</h2>
        <h3>Verticals</h3>
        <p>
          Internet&emsp;|&emsp;Social/marketplace
          platforms&emsp;|&emsp;Communications&emsp;|&emsp;Software and services
        </p>
        <Companies type="technology" state={state} />
        <h2>Consumer</h2>
        <h3>Verticals</h3>
        <p>
          Retail/e-commerce&emsp;|&emsp;Consumer
          finance&emsp;|&emsp;Travel/leisure&emsp;|&emsp;Services
        </p>
        <Companies type="consumer" state={state} />
        <h2>Industrial</h2>
        <h3>Verticals</h3>
        <p>
          Multi-industry/industrial
          services&emsp;|&emsp;Equipment/machinery&emsp;|&emsp;Transport/logistics&emsp;|&emsp;Software
          and services
        </p>
        <Companies type="industrial" state={state} />
        {state && (
          <Text type="disclaimer">
            * The gray-shaded investments referred to above were originated, executed and
            managed by members of the GPI investment team while at a prior firm.
            Other investment professionals at such other firm had input or
            reviewed such transactions. The selected transactions illustrate the
            team’s investment experience and do not represent a comprehensive
            list.
          </Text>
        )}
      </Clamp>
    </>
  )
}

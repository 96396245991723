import React from "react"
import PropTypes from "prop-types"
import Card from "components/card"
import Grid from "components/grid"
import { filter, includes, join } from "hooks/use-array"
import useCompanies from "hooks/use-companies"
import * as styles from "./companies.module.less"

export default function Companies({ type, state }) {
  const data = useCompanies(type)
  const current = useCompanies("current")

  const [companies, setCompanies] = React.useState(null)
  React.useEffect(() => {
    if (data && current) {
      const currentIds = current.map(i => i.id)
      setCompanies(state ? data : filter(data, currentIds))
    }
  }, [state, data, current])

  return (
    companies && (
      <Grid className={styles.root}>
        {React.Children.toArray(
          companies.map(i => (
            <Card>
              <div
                className={join(
                  styles.show,
                  !includes(current, i.id) && styles.prior
                )}
              >
                <img
                  className={styles.img}
                  src={i.img.publicURL}
                  alt={i.name}
                />
              </div>
            </Card>
          ))
        )}
      </Grid>
    )
  )
}

Companies.propTypes = {
  type: PropTypes.oneOf([`consumer`, `industrial`, `technology`]).isRequired,
  current: PropTypes.bool,
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { arrange, filter } from "hooks/use-array"

//
//  List of company IDs by type and in order of presentation
//
const byConsumer = new Set()
byConsumer
  .add("postmates")
  .add("hopper")
  .add("pharmapacks")
  .add("grab")
  .add("sofi")
  .add("michael_kors")
  .add("ganji")
  .add("zalando")
  .add("jdcom")
  .add("btg_pactual")
  .add("kyobo_life")
  .add("hbc")
  .add("saks_fifth_avenue")

const byIndustrial = new Set()
byIndustrial
  .add("pike")
  .add("stella_jones")
  .add("ge_aviation")
  .add("hitachi")
  .add("cemex_latam_holdings")
  .add("airbus")
  .add("constellium")

const byTechnology = new Set()
byTechnology
  .add("couchbase")
  .add("legalzoom")
  .add("zeta")
  .add("moloco")
  .add("sprint")
  .add("kpn")
  .add("vodafone")
  .add("tmx")
  .add("tmx_cds")
  .add("tmx_alpha")

//
//  List of company IDs in current portfolio
//
const byCurrent = new Set()
byCurrent
  .add("postmates")
  .add("hopper")
  .add("pharmapacks")
  .add("couchbase")
  .add("grab")
  .add("sofi")
  .add("legalzoom")
  .add("zeta")
  .add("pike")
  .add("stella_jones")
  .add("moloco")

const companies = new Map()
companies
  .set("consumer", byConsumer)
  .set("industrial", byIndustrial)
  .set("technology", byTechnology)
  .set("current", byCurrent)

/**
 *  Get list of companies
 *
 *  @param {enum} type Companies by type ["consumer", "technology", "industrial""]
 *  @param {boolean} current Filter companies by current portfolio
 *  @return {array}
 */
export default function useCompanies(type) {
  const [state, setState] = React.useState(null)

  const query = useStaticQuery(graphql`
    query CompaniesQuery {
      allCompaniesJson {
        nodes {
          id
          name
          img {
            publicURL
          }
        }
      }
    }
  `).allCompaniesJson.nodes

  React.useEffect(() => {
    const list = arrange(filter(query, [...companies.get(type)]), [
      ...companies.get(type),
    ])
    setState(list)
  }, [query, type])

  return state
}
